.Home {
  .filter-container > div {
    padding: 0;
  }
}
.text {
  h1 {
    font-family: $global-font-family;
    font-size: 32px;
    @include breakpoint(sm-down) {
      font-size: 22px;
    }
    font-weight: lighter;
    line-height: 32px;
    letter-spacing: 0.3px;
    color: #131413;
    margin-bottom: 20px;
  }
  p {
    font-family: $global-font-family;
    font-size: 32px;
    line-height: 42px;
    @include breakpoint(sm-down) {
      font-size: 22px;
      line-height: 32px;
    }

    letter-spacing: 0.3px;
    color: #131413;
    max-width: 915px;
    a {
      text-decoration: none;
      border-bottom: 2px solid #000000;
      color: #000000;
      &:hover {
        color: $link-hover-color;
        border-bottom: 2px solid $link-hover-color;
      }
    }
  }
  strong,
  b {
    font-weight: 500;
  }
}
