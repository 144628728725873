/*========================================================
Global Styles
========================================================*/
@import "../../node_modules/normalize-scss/sass/normalize/import-now";
@import "core/fonts";
@import "core/breakpoints";
@import "core/variables";
@import "core/helpers";
@import "core/mixins";
@import "core/forms";
@import "core/typography";

// Layout
@import 'layouts/App';

// Components
@import 'components/Catalog';
@import 'components/Header';
@import 'components/Item';
@import 'components/Filter';

//Pages
@import 'pages/Home';
@import 'pages/Stories';
@import 'pages/User';