/*=========================================================
///////////////////////////////////////////////////////////
	COLORS
///////////////////////////////////////////////////////////
=========================================================*/
$black: 					      #000000;
$white: 					      #FFFFFF; 
$gray:                  #3D3D3D;

$placeholder-color:     #A8A9A9;
$blue:                  #3489FF;

$text-color: $black;
$link-hover-color: rgba(0,0,0,0.8);
$heading-color: $black;
// $placeholder-color: #cecece; //f00;


/*=========================================================
///////////////////////////////////////////////////////////
	FONTS
///////////////////////////////////////////////////////////
=========================================================*/


$global-font-family: nimbus-sans, sans-serif; 
$secondary-font-family: nimbus-sans, sans-serif;
$heading-font-family: nimbus-sans, sans-serif;
$button-font-family: nimbus-sans, sans-serif; 


/*=========================================================
///////////////////////////////////////////////////////////
  Layouts 
///////////////////////////////////////////////////////////
=========================================================*/
$body-background-color: #fff;

$max-content-width: 1384px;
:root {
  --horizontal-padding: 8px;
  @include breakpoint(md){
    --horizontal-padding: 80px;
  }
}

$height-photo-modal-header: 100px;
$modal-z-index: 500;