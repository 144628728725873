@import url(https://use.typekit.net/sug5znb.css);
/*========================================================
Global Styles
========================================================*/
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

/*=========================================================
///////////////////////////////////////////////////////////
  FONTS
///////////////////////////////////////////////////////////
=========================================================*/
@font-face {
  font-family: "Baskerville";
  src: local("Baskerville"), url("/fonts/baskerville-normal.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: "Baskerville";
  src: local("Baskerville"), url("/fonts/baskerville-regular.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal; }

* {
  font-weight: lighter; }

/*=========================================================
///////////////////////////////////////////////////////////
  BREAKPOINTS
///////////////////////////////////////////////////////////
=========================================================*/
/*=========================================================
///////////////////////////////////////////////////////////
  MOBILE -- FIRST
///////////////////////////////////////////////////////////
=========================================================*/
/* 1 & above --> xs*/
/* 576 & above --> sm*/
/* 768 & above --> md*/
/* 992 & above --> lg*/
/* 1200 & above --> xl*/
/*================================================
!!!!!!!!!!Syntax of myxin!!!!!!!!!!!!!!!
  @include breakpoint(sm) {
    color: red;
  }
*/
/*=======================================================================
/////////////////////////////////////////////////////////////////////////
                            ONLY
  xs-only sm-only md-only lg-only xl-only 
/////////////////////////////////////////////////////////////////////////
=======================================================================*/
/* 1   - 576 --> xs-only*/
/* 576 - 768 --> sm-only*/
/* 768 - 992 --> md-only*/
/* 992 - 1200 --> lg-only*/
/* 1200 & above --> xl-only*/
/*================================================
!!!!!!!!!!Syntax of myxin!!!!!!!!!!!!!!!
  @include breakpoint(sm-only) {
    padding: 20px;
  }
*/
/*=========================================================
///////////////////////////////////////////////////////////
	COLORS
///////////////////////////////////////////////////////////
=========================================================*/
/*=========================================================
///////////////////////////////////////////////////////////
	FONTS
///////////////////////////////////////////////////////////
=========================================================*/
/*=========================================================
///////////////////////////////////////////////////////////
  Layouts 
///////////////////////////////////////////////////////////
=========================================================*/
:root {
  --horizontal-padding: 8px;
  /*======================+MOBILE-FIRST+==========================================*/ }
  @media (min-width: 768px) {
    :root {
      --horizontal-padding: 80px; } }

/*=========================================================
  01. #PADDING & MARGIN
=========================================================*/
.float-right {
  float: right; }

.float-left {
  float: left; }

.clearfix {
  clear: both; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

a {
  cursor: pointer; }

/*=========================================================
///////////////////////////////////////////////////////////
  01 OUTLINE
///////////////////////////////////////////////////////////
=========================================================*/
input {
  color: #000000; }

input::-webkit-input-placeholder {
  color: #A8A9A9 !important; }

input:-moz-placeholder {
  /* Firefox 18- */
  color: #A8A9A9 !important; }

input::-moz-placeholder {
  /* Firefox 19+ */
  color: #A8A9A9 !important; }

input:-ms-input-placeholder {
  color: #A8A9A9 !important; }

textarea::-webkit-input-placeholder {
  color: #A8A9A9 !important; }

textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #A8A9A9 !important; }

textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #A8A9A9 !important; }

textarea:-ms-input-placeholder {
  color: #A8A9A9 !important; }

/*=========================================================
  01. #GLOBAL
=========================================================*/
html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  font-family: nimbus-sans, sans-serif;
  color: #000000;
  background-color: #fff; }

body * {
  font-family: nimbus-sans, sans-serif; }

p {
  margin: 0 0 1em; }
  p:last-child {
    margin: 0; }

a {
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  text-decoration: none;
  color: #000000;
  webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  a:hover {
    text-decoration: underline;
    color: rgba(0, 0, 0, 0.8); }

button {
  webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

h1, h2, h3, h4 {
  margin: 0 0 1em;
  word-break: break-word; }

.App {
  margin: 0 auto;
  max-width: 1384px;
  min-height: 100vh; }
  .App .main {
    min-height: calc(100vh);
    padding: 0 var(--horizontal-padding) 0;
    /*======================+MOBILE-FIRST+==========================================*/
    background-color: #FFFFFF; }
    @media (min-width: 768px) {
      .App .main {
        padding: 0 var(--horizontal-padding) 0; } }

.not-display-on-mobile {
  display: none;
  /*======================+MOBILE-FIRST+==========================================*/ }
  @media (min-width: 768px) {
    .not-display-on-mobile {
      display: block; } }

.Catalog {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  --gap-horizontal: 16px;
  --gap-vertical: 40px;
  --number-items: 2;
  margin: 0 calc(-1 * var(--gap-horizontal) / 2) 40px;
  /*======================+MOBILE-FIRST+==========================================*/
  /*======================+MOBILE-FIRST+==========================================*/ }
  @media (min-width: 768px) {
    .Catalog {
      --gap-horizontal: 48px;
      --gap-vertical: 48px;
      --number-items: 4; } }
  @media (min-width: 1200px) {
    .Catalog {
      --gap-horizontal: 96px;
      --gap-vertical: 58px;
      --number-items: 5; } }
  .Catalog.xl {
    --number-items: 2;
    /*======================+MOBILE-FIRST+==========================================*/
    /*======================+MOBILE-FIRST+==========================================*/ }
    @media (min-width: 768px) {
      .Catalog.xl {
        --gap-horizontal: 100px;
        --gap-vertical: 48px;
        --number-items: 3; } }
    @media (min-width: 1200px) {
      .Catalog.xl {
        --gap-horizontal: 200px;
        --gap-vertical: 48px;
        --number-items: 3; } }
    .Catalog.xl .item-text {
      text-align: center; }
    .Catalog.xl .item-img {
      object-position: center center; }
  .Catalog .item-container {
    width: calc( 100% / var(--number-items));
    padding: 0 calc(var(--gap-horizontal) / 2);
    margin-bottom: var(--gap-vertical); }

.Header .headroom-wrapper {
  width: 100%;
  max-width: 1384px;
  margin: 0px auto;
  background-color: #FFFFFF;
  position: relative;
  z-index: 50; }

.Header .headroom {
  width: 100%;
  max-width: 1384px;
  margin: 0px auto;
  will-change: transform;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1; }
  .Header .headroom--unfixed {
    position: relative;
    transform: translateY(0); }
  .Header .headroom--scrolled {
    transition: transform 200ms ease-in-out; }
  .Header .headroom--unpinned {
    position: fixed;
    transform: translateY(-100%); }
  .Header .headroom--pinned {
    position: fixed;
    transform: translateY(0%); }

.Header.mobileMenuIsOpen .headroom, .Header.searchIsOpen .headroom {
  position: fixed;
  transform: translateY(0%); }

.Header .header-container {
  width: 100%;
  max-width: 1384px;
  height: 64px;
  margin: 0 auto;
  padding: 0 var(--horizontal-padding);
  /*======================+MOBILE-FIRST+==========================================*/
  background-color: #fff;
  display: flex;
  align-items: center; }
  @media (min-width: 768px) {
    .Header .header-container {
      height: 96px;
      padding: 0 var(--horizontal-padding); } }

.Header .logo-link {
  outline: 0;
  outline-offset: 0; }
  .Header .logo-link :active, .Header .logo-link :hover, .Header .logo-link :focus {
    outline: 0;
    outline-offset: 0; }

.Header .header-right {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center; }

.Header .nav-container {
  display: none;
  /*======================+MOBILE-FIRST+==========================================*/ }
  @media (min-width: 1200px) {
    .Header .nav-container {
      display: block; } }

.Header .nav {
  font-size: 22px;
  line-height: 29px; }

.Header .nav-list {
  margin: 0;
  padding: 0 25px;
  list-style-type: none;
  display: flex; }

.Header .nav-item:not(:last-child) {
  padding-right: 24px; }

.Header .cart-container {
  display: flex;
  align-items: center;
  margin-right: 25px;
  /*======================+MOBILE-FIRST+==========================================*/ }
  @media screen and (max-width: 767px) {
    .Header .cart-container {
      margin-right: 14px; } }

.Header .cart-icon {
  display: block;
  margin-right: 10px; }

.Header .cart-number {
  font-family: nimbus-sans, sans-serif;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.23px;
  color: #131413; }

.Header .user-container {
  /*======================+MOBILE-FIRST+==========================================*/ }
  @media screen and (max-width: 1199px) {
    .Header .user-container {
      display: none; } }

.Header .login-link {
  outline: 0;
  outline-offset: 0;
  font-size: 24px;
  font-weight: lighter;
  color: #131413;
  text-decoration: none; }
  .Header .login-link :active, .Header .login-link :hover, .Header .login-link :focus {
    outline: 0;
    outline-offset: 0; }
  .Header .login-link:not(:last-child) {
    margin-right: 25px; }

.Header .hamburger-container {
  width: 48px;
  height: 48px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  /*======================+MOBILE-FIRST+==========================================*/ }
  @media (min-width: 1200px) {
    .Header .hamburger-container {
      display: none; } }

.Header .mobile-nav-container {
  display: none;
  position: fixed;
  top: 64px;
  max-height: calc(100vh - 64px);
  /*======================+MOBILE-FIRST+==========================================*/
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px var(--horizontal-padding) 20px;
  z-index: 40;
  overflow-y: auto;
  background-color: #FFFFFF; }
  @media (min-width: 768px) {
    .Header .mobile-nav-container {
      top: 96px;
      max-height: calc(100vh - 96px); } }

.Header.mobileMenuIsOpen .mobile-nav-container {
  /*======================+MOBILE-FIRST+==========================================*/ }
  @media screen and (max-width: 1199px) {
    .Header.mobileMenuIsOpen .mobile-nav-container {
      display: block; } }

.Header .mobile-nav-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column; }

.Header .mobile-nav-item:not(:last-child) {
  margin-bottom: 28px; }

.Header .mobile-nav-link {
  outline: 0;
  outline-offset: 0;
  font-size: 26px;
  line-height: 28px; }
  .Header .mobile-nav-link :active, .Header .mobile-nav-link :hover, .Header .mobile-nav-link :focus {
    outline: 0;
    outline-offset: 0; }
  .Header .mobile-nav-link.active {
    text-decoration: underline; }

.Header.alwaysShow .headroom {
  position: fixed; }

.Item .item-link {
  display: block;
  transition: transform .2s ease-in-out; }
  .Item .item-link:hover {
    text-decoration: none; }
    .Item .item-link:hover .item-img {
      transform: translateY(-10px); }
    .Item .item-link:hover .item-artists, .Item .item-link:hover .item-title {
      text-decoration: underline;
      opacity: 0.8; }

.Item .item-img-container {
  position: relative;
  padding-bottom: 138%;
  margin-bottom: 6px; }

.Item .item-img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: left bottom;
  transition: transform .2s ease-in-out; }

.Item .item-artists, .Item .item-title {
  display: block;
  word-break: break-word;
  font-size: 14px;
  line-height: 1.17;
  letter-spacing: 0.01px;
  color: #282828;
  /*======================+MOBILE-FIRST+==========================================*/ }
  @media (min-width: 768px) {
    .Item .item-artists, .Item .item-title {
      font-size: 16px;
      line-height: 1.125; } }

.Item .item-title {
  font-weight: 500;
  margin-bottom: 6px; }

.Item .item-artists {
  margin-bottom: 6px; }

.Item .price-container {
  padding-top: 6px; }

.Item .item-price {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 1px 10px;
  min-height: 20px;
  border-radius: 10px;
  background-color: #F6F7F9;
  color: #96989E;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.22px;
  line-height: 1.4;
  text-decoration: none; }

.Item.catalog-321-item {
  width: 264px; }
  .Item.catalog-321-item .item-text {
    text-align: center; }

.item-filter {
  /*======================+MOBILE-FIRST+==========================================*/ }
  .item-filter div {
    display: flex;
    flex-wrap: wrap; }
    .item-filter div.selected-filter button:focus, .item-filter div.selected-filter button:hover, .item-filter div.selected-filter button:active {
      color: white;
      border: 1px solid black; }
      .item-filter div.selected-filter button:focus svg, .item-filter div.selected-filter button:hover svg, .item-filter div.selected-filter button:active svg {
        fill: black !important; }
    .item-filter div .filter-option-container {
      margin-top: 20px;
      /*======================+MOBILE-FIRST+==========================================*/ }
      @media screen and (max-width: 767px) {
        .item-filter div .filter-option-container {
          margin-top: 12px; } }
      .item-filter div .filter-option-container:not(:last-child) {
        margin-right: 8px; }
      .item-filter div .filter-option-container .selected-button {
        background-color: #5B48B6;
        border: none;
        color: #fff; }
        .item-filter div .filter-option-container .selected-button:focus, .item-filter div .filter-option-container .selected-button:hover, .item-filter div .filter-option-container .selected-button:active {
          border: none; }
        .item-filter div .filter-option-container .selected-button svg {
          fill: #fff !important; }
      .item-filter div .filter-option-container button {
        border-radius: 4px;
        padding: 0;
        height: unset !important;
        min-height: unset !important;
        font-size: 16px;
        height: 24px;
        flex: 0 0 auto;
        padding: 6px 15px;
        white-space: wrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block; }
      .item-filter div .filter-option-container .filter-button-content {
        flex-direction: row;
        justify-content: center;
        align-items: center; }
      .item-filter div .filter-option-container .filter-left-icon {
        margin-right: 10px; }
      .item-filter div .filter-option-container .filter-right-icon {
        margin-left: 10px; }
  .item-filter.big div .filter-option-container:not(:last-child) {
    margin-right: 16px;
    /*======================+MOBILE-FIRST+==========================================*/ }
    @media screen and (max-width: 767px) {
      .item-filter.big div .filter-option-container:not(:last-child) {
        margin-right: 12px; } }
  .item-filter.big div .filter-option-container button {
    font-size: 16px;
    border-radius: 7px;
    padding: 11px 16px !important;
    /*======================+MOBILE-FIRST+==========================================*/ }
    @media screen and (max-width: 767px) {
      .item-filter.big div .filter-option-container button {
        font-size: 12px;
        max-height: 30px;
        justify-content: center;
        padding-block: 0px;
        display: flex;
        border-radius: 4px; } }

.Home .filter-container > div {
  padding: 0; }

.text h1 {
  font-family: nimbus-sans, sans-serif;
  font-size: 32px;
  /*======================+MOBILE-FIRST+==========================================*/
  font-weight: lighter;
  line-height: 32px;
  letter-spacing: 0.3px;
  color: #131413;
  margin-bottom: 20px; }
  @media screen and (max-width: 767px) {
    .text h1 {
      font-size: 22px; } }

.text p {
  font-family: nimbus-sans, sans-serif;
  font-size: 32px;
  line-height: 42px;
  /*======================+MOBILE-FIRST+==========================================*/
  letter-spacing: 0.3px;
  color: #131413;
  max-width: 915px; }
  @media screen and (max-width: 767px) {
    .text p {
      font-size: 22px;
      line-height: 32px; } }
  .text p a {
    text-decoration: none;
    border-bottom: 2px solid #000000;
    color: #000000; }
    .text p a:hover {
      color: rgba(0, 0, 0, 0.8);
      border-bottom: 2px solid rgba(0, 0, 0, 0.8); }

.text strong,
.text b {
  font-weight: 500; }

.Stories .filter-list {
  justify-content: unset;
  /*======================+MOBILE-FIRST+==========================================*/ }
  @media (min-width: 768px) {
    .Stories .filter-list {
      justify-content: center; } }

.StoryList {
  margin-top: 20px;
  /*======================+MOBILE-FIRST+==========================================*/ }
  @media (min-width: 768px) {
    .StoryList {
      margin-top: 50px; } }
  .StoryList .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 calc(-1 * var(--story-gap-horizontal) / 2);
    --story-gap-horizontal: 28px;
    --story-gap-vertical: 40px;
    --story-number-items: 1;
    /*======================+MOBILE-FIRST+==========================================*/
    /*======================+MOBILE-FIRST+==========================================*/ }
    @media (min-width: 768px) {
      .StoryList .list {
        --story-gap-horizontal: 100% / 12;
        --story-gap-vertical: 24px;
        --story-number-items: 2;
        margin: 0 calc(-1 * var(--story-gap-horizontal) / 2); } }
    @media (min-width: 1200px) {
      .StoryList .list {
        --story-gap-horizontal: 100% / 12;
        --story-gap-vertical: 24px;
        --story-number-items: 2;
        margin: 0 calc(-1 * var(--story-gap-horizontal) / 2); } }
    .StoryList .list .StoryCard {
      display: inline-block;
      width: calc(100% / var(--story-number-items));
      padding: 0 calc(1 * var(--story-gap-horizontal) / 2 - 0.3%);
      margin-bottom: var(--story-gap-vertical); }
      .StoryList .list .StoryCard a {
        display: inline-block;
        width: 100%; }
        .StoryList .list .StoryCard a:hover {
          text-decoration: none; }
      .StoryList .list .StoryCard h1 {
        font-size: 32px;
        font-family: Baskerville;
        font-weight: normal;
        color: #131413;
        letter-spacing: 0.29px;
        line-height: 38px;
        margin-bottom: 7px;
        /*======================+MOBILE-FIRST+==========================================*/ }
        @media screen and (max-width: 767px) {
          .StoryList .list .StoryCard h1 {
            font-size: 27px;
            margin-bottom: 0px; } }
      .StoryList .list .StoryCard .author {
        font-size: 16px;
        line-height: 24px; }
      .StoryList .list .StoryCard .story-card-header {
        margin-bottom: 17px;
        font-size: 14px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        /*======================+MOBILE-FIRST+==========================================*/ }
        @media screen and (max-width: 767px) {
          .StoryList .list .StoryCard .story-card-header {
            display: none; } }
        .StoryList .list .StoryCard .story-card-header .status {
          border-radius: 18px;
          background: #f7f8fa;
          margin-top: -3px;
          padding: 3px 11px;
          color: #a2a2a2; }
      .StoryList .list .StoryCard img {
        width: 100%;
        object-fit: cover;
        max-height: 330px;
        height: 330px;
        /*======================+MOBILE-FIRST+==========================================*/ }
        @media screen and (max-width: 767px) {
          .StoryList .list .StoryCard img {
            max-height: 66vw; } }

.Stories .sticky .filters:not(.story-type-filters) {
  transition: margin-bottom 250ms; }

.Stories .sticky .filters {
  margin-bottom: 0px; }

.Stories .sticky .sort-by-container {
  display: none !important; }

.Stories .sticky .stories-count {
  display: none !important; }

.Stories .sticky .story-type-filters-container.my-stories,
.Stories .sticky .filters {
  /*======================+MOBILE-FIRST+==========================================*/ }
  @media (min-width: 768px) {
    .Stories .sticky .story-type-filters-container.my-stories,
    .Stories .sticky .filters {
      display: block !important; } }

.Stories .sticky .filter-container > div {
  margin-left: 0;
  /*======================+MOBILE-FIRST+==========================================*/ }
  @media (min-width: 1200px) {
    .Stories .sticky .filter-container > div {
      padding-left: 0;
      padding-right: 0; } }

.Stories .sticky .selected-filters.my-stories {
  margin-left: 0; }

.Stories > h1 {
  /*======================+MOBILE-FIRST+==========================================*/
  /*======================+MOBILE-FIRST+==========================================*/ }
  @media screen and (max-width: 767px) {
    .Stories > h1 {
      font-size: 34px;
      text-align: unset; } }
  @media (min-width: 768px) {
    .Stories > h1 {
      margin-bottom: 35px; } }

.Stories .story-type-filters {
  display: flex;
  /*======================+MOBILE-FIRST+==========================================*/ }
  @media (min-width: 768px) {
    .Stories .story-type-filters {
      display: block; } }

.Stories .selected-filters.my-stories {
  margin-left: 0px;
  /*======================+MOBILE-FIRST+==========================================*/ }
  @media (min-width: 768px) {
    .Stories .selected-filters.my-stories {
      margin-left: -40px; } }

.Stories .filters {
  /*======================+MOBILE-FIRST+==========================================*/ }
  @media screen and (max-width: 767px) {
    .Stories .filters {
      display: flex;
      margin-bottom: 15px; } }
  .Stories .filters li {
    /*======================+MOBILE-FIRST+==========================================*/ }
    @media screen and (max-width: 767px) {
      .Stories .filters li {
        transform-origin: left;
        margin-bottom: 8px; } }

.Stories .story-type-filters-container {
  display: flex;
  /*======================+MOBILE-FIRST+==========================================*/ }
  @media (min-width: 768px) {
    .Stories .story-type-filters-container .story-type-filters {
      margin: auto; } }
  .Stories .story-type-filters-container .sort-by-container {
    display: none;
    /*======================+MOBILE-FIRST+==========================================*/ }
    @media (min-width: 768px) {
      .Stories .story-type-filters-container .sort-by-container {
        display: flex; } }
  .Stories .story-type-filters-container.my-stories {
    display: unset;
    font-size: 22px;
    /*======================+MOBILE-FIRST+==========================================*/ }
    .Stories .story-type-filters-container.my-stories > span {
      display: block;
      /*======================+MOBILE-FIRST+==========================================*/ }
      @media screen and (max-width: 767px) {
        .Stories .story-type-filters-container.my-stories > span {
          display: none; } }
    @media (min-width: 768px) {
      .Stories .story-type-filters-container.my-stories {
        display: flex; }
        .Stories .story-type-filters-container.my-stories > span {
          margin-top: 5px; } }

.Stories .stories-page-title-container {
  display: flex;
  flex-direction: column-reverse;
  /*======================+MOBILE-FIRST+==========================================*/ }
  @media (min-width: 768px) {
    .Stories .stories-page-title-container {
      display: block; } }

.Stories .create-a-story {
  margin-bottom: 25px;
  display: flex;
  /*======================+MOBILE-FIRST+==========================================*/ }
  @media (min-width: 768px) {
    .Stories .create-a-story {
      display: block;
      margin-bottom: 0px; }
      .Stories .create-a-story.my-stories {
        margin-top: 30px; } }
  .Stories .create-a-story button {
    /*======================+MOBILE-FIRST+==========================================*/ }
    @media screen and (max-width: 767px) {
      .Stories .create-a-story button {
        width: auto; } }

.Stories .StoryList .list {
  /*======================+MOBILE-FIRST+==========================================*/ }
  @media (min-width: 1200px) {
    .Stories .StoryList .list .StoryCard:nth-child(5n), .Stories .StoryList .list .StoryCard:nth-child(5n-1), .Stories .StoryList .list .StoryCard:nth-child(5n-2) {
      width: calc(100% / (var(--story-number-items) + 1));
      margin-bottom: 115px; }
      .Stories .StoryList .list .StoryCard:nth-child(5n) img, .Stories .StoryList .list .StoryCard:nth-child(5n-1) img, .Stories .StoryList .list .StoryCard:nth-child(5n-2) img {
        height: 200px; } }

.StoryPreview {
  margin-top: 30px;
  margin-bottom: 30px;
  /*======================+MOBILE-FIRST+==========================================*/ }
  .StoryPreview .page-title {
    font-size: 3.375em;
    margin-bottom: 17px; }
  @media screen and (max-width: 575px) {
    .StoryPreview .story-label {
      display: flex;
      flex-direction: column-reverse; }
      .StoryPreview .story-label > * {
        margin-right: auto; }
      .StoryPreview .story-label a {
        display: flex; }
        .StoryPreview .story-label a button {
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 10px; }
      .StoryPreview .story-label + .story {
        padding-top: 0px; } }
  .StoryPreview .story {
    padding: 25px 0;
    max-width: 886px;
    margin: 0 auto 80px; }
    .StoryPreview .story .header .subline {
      display: flex;
      justify-content: center;
      letter-spacing: 0.78px;
      font-size: 0.875em; }
      .StoryPreview .story .header .subline > * {
        margin: 1em 3em 2em; }
    .StoryPreview .story .header .videoContainer {
      width: 100%;
      margin-bottom: 50px;
      text-align: center;
      max-height: 800px !important;
      overflow: hidden; }
      .StoryPreview .story .header .videoContainer iframe {
        width: 100%;
        max-height: 800px !important; }
    .StoryPreview .story .header img {
      width: 100%;
      margin-bottom: 50px; }
    .StoryPreview .story .header .related-to {
      display: flex; }
      .StoryPreview .story .header .related-to div:first-of-type {
        margin-right: 60px;
        font-size: 16px; }
      .StoryPreview .story .header .related-to div:last-of-type {
        font-weight: bold;
        font-family: nimbus-sans; }
        .StoryPreview .story .header .related-to div:last-of-type a {
          display: block;
          margin-bottom: 2px; }
        .StoryPreview .story .header .related-to div:last-of-type p {
          display: inline-block; }
    .StoryPreview .story .content * {
      margin-top: 50px;
      color: #131413;
      font-family: Baskerville;
      font-size: 22px;
      letter-spacing: 1.22px;
      line-height: 32px;
      white-space: break-spaces; }
    @media (max-width: 768px) {
      .StoryPreview .story .header .hkpKuJ {
        text-align: left; }
      .StoryPreview .story .header .subline > * {
        margin-left: 0px;
        justify-content: flex-start;
        margin-right: 5px; }
      .StoryPreview .story .header .related-to {
        display: block; }
      .StoryPreview .story .header p:last-child {
        margin-bottom: 5px; }
      .StoryPreview .story .header .filter-list {
        margin-inline: 0px; } }

.story-form .editions-select div[class*="placeholder"] {
  /*======================+MOBILE-FIRST+==========================================*/ }
  @media screen and (max-width: 767px) {
    .story-form .editions-select div[class*="placeholder"] {
      font-size: 16px; } }

.story-form {
  color: #dc3545; }
  .story-form .errors-message {
    margin-bottom: 0 !important; }
  .story-form ul {
    margin-top: 0; }

.User .loadmore {
  border-radius: 4px;
  height: 40px;
  font-size: 16px;
  /*======================+MOBILE-FIRST+==========================================*/ }
  @media screen and (max-width: 767px) {
    .User .loadmore {
      max-width: 210px; } }

.User .shopTitle {
  margin-bottom: 16px;
  margin-top: 0px;
  text-align: unset;
  font-size: 40px;
  /*======================+MOBILE-FIRST+==========================================*/ }
  @media screen and (max-width: 767px) {
    .User .shopTitle {
      font-size: 24px;
      margin-bottom: 0px; } }

.User .shopLocation {
  font-size: 14px;
  /*======================+MOBILE-FIRST+==========================================*/ }
  @media screen and (max-width: 767px) {
    .User .shopLocation {
      display: flex;
      align-items: center;
      justify-content: space-between; } }

.User .filter-list {
  justify-content: unset;
  /*======================+MOBILE-FIRST+==========================================*/ }
  @media (min-width: 768px) {
    .User .filter-list {
      justify-content: center; } }

.User .item-filter-container .header-filter {
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px; }
  .User .item-filter-container .header-filter button {
    margin-right: 16px;
    border-radius: 4px;
    font-size: 16px;
    margin-bottom: 16px;
    /*======================+MOBILE-FIRST+==========================================*/ }
    @media screen and (max-width: 767px) {
      .User .item-filter-container .header-filter button {
        font-size: 12px;
        width: auto;
        margin-right: 12px;
        padding-block: 0px;
        max-height: 30px;
        margin-bottom: 12px; } }
  .User .item-filter-container .header-filter .hovered-button {
    color: #fff;
    background-color: #000; }

.User .item-filter-container.filter-children-opened {
  margin-bottom: 40px; }

.User .item-filter-container .filter-description {
  margin-top: 16px;
  font-size: 14.5px;
  margin-bottom: 16px; }

.User .item-filter-container .item-filter-wrapper {
  align-items: center;
  display: flex; }
  .User .item-filter-container .item-filter-wrapper .filter-option-container:not(:last-child) {
    margin-right: 16px;
    /*======================+MOBILE-FIRST+==========================================*/ }
    @media screen and (max-width: 767px) {
      .User .item-filter-container .item-filter-wrapper .filter-option-container:not(:last-child) {
        margin-right: 8px; } }
  .User .item-filter-container .item-filter-wrapper > *:not(:last-child).item-filter {
    margin-bottom: 8px; }

.User .item-filter-container .item-filter button {
  font-size: 16px;
  padding: 9px 16px;
  width: auto !important;
  /*======================+MOBILE-FIRST+==========================================*/ }
  @media screen and (max-width: 767px) {
    .User .item-filter-container .item-filter button {
      padding-block: 7px; } }

.User .go-back {
  background-color: #000;
  color: #fff;
  border-radius: 4px;
  /*======================+MOBILE-FIRST+==========================================*/ }
  .User .go-back svg {
    margin-right: 10px; }
  .User .go-back:hover {
    background-color: #000;
    color: #fff; }
    .User .go-back:hover svg {
      filter: invert(0); }
  .User .go-back:focus svg {
    filter: invert(0); }
  @media screen and (max-width: 767px) {
    .User .go-back {
      width: 150px;
      min-height: 32px;
      font-size: 14px;
      padding: 0px; }
      .User .go-back svg {
        width: 12px; } }

.User .info-container {
  font-size: 14px;
  display: flex; }
  .User .info-container a {
    display: flex;
    align-items: center; }
    .User .info-container a svg {
      margin-right: 8px; }
  .User .info-container div:first-child {
    margin-right: 30px; }

.User .info-details {
  display: flex;
  justify-content: space-between;
  /*======================+MOBILE-FIRST+==========================================*/ }
  .User .info-details h2 {
    margin-top: 24px;
    margin-bottom: 12px; }
  .User .info-details p {
    font-size: 14px;
    line-height: 20px; }
  .User .info-details div {
    max-width: 50%; }
    .User .info-details div img {
      display: block; }
  .User .info-details .shipping-container {
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 20px;
    display: grid;
    max-width: 100%;
    grid-template-columns: auto auto; }
    .User .info-details .shipping-container .shipping-item {
      margin-bottom: 20px;
      max-width: 90%;
      /*======================+MOBILE-FIRST+==========================================*/ }
      @media screen and (max-width: 767px) {
        .User .info-details .shipping-container .shipping-item {
          margin-bottom: 10px;
          max-width: 60%; } }
      .User .info-details .shipping-container .shipping-item h4 {
        font-weight: 500;
        margin: 0px; }
      .User .info-details .shipping-container .shipping-item .cost {
        max-width: 100%;
        display: flex;
        justify-content: space-between; }
        .User .info-details .shipping-container .shipping-item .cost .price {
          width: 120px;
          display: flex;
          align-items: flex-start; }
  @media screen and (max-width: 767px) {
    .User .info-details div {
      max-width: 100%; }
    .User .info-details .shipping-container {
      grid-template-columns: auto; } }
  .User .info-details .orderTerms h1 {
    margin-top: 25px;
    margin-bottom: 0px;
    font-size: 1.5em; }
  .User .info-details .orderTerms p {
    margin: 0px; }

.ItemList {
  margin-top: 24px;
  /*======================+MOBILE-FIRST+==========================================*/ }
  @media (min-width: 768px) {
    .ItemList {
      margin-top: 24px; } }
  .ItemList .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 calc(-1 * var(--story-gap-horizontal) / 2);
    --story-gap-horizontal: 28px;
    --story-gap-vertical: 40px;
    --story-number-items: 1;
    /*======================+MOBILE-FIRST+==========================================*/
    /*======================+MOBILE-FIRST+==========================================*/
    /*======================+MOBILE-FIRST+==========================================*/ }
    @media screen and (max-width: 767px) {
      .ItemList .list {
        --story-gap-horizontal: 10px; } }
    @media (min-width: 768px) {
      .ItemList .list {
        --story-gap-horizontal: 100% / 12;
        --story-gap-vertical: 32x;
        --story-number-items: 2;
        margin: 0 calc(-1 * var(--story-gap-horizontal) / 2); } }
    @media (min-width: 1200px) {
      .ItemList .list {
        --story-gap-horizontal: 100% / 12;
        --story-gap-vertical: 32px;
        --story-number-items: 2;
        margin: 0 calc(-1 * var(--story-gap-horizontal) / 2); } }
    .ItemList .list .ItemCard, .ItemList .list .ItemCard2, .ItemList .list .shopItemCard, .ItemList .list .storyItemCard {
      display: inline-block;
      margin: auto;
      margin-top: 0px;
      width: calc(100% / var(--story-number-items));
      padding: 0 calc(1 * var(--story-gap-horizontal) / 2 - 0.3%);
      margin-bottom: var(--story-gap-vertical);
      height: auto;
      /*======================+MOBILE-FIRST+==========================================*/ }
      @media screen and (max-width: 767px) {
        .ItemList .list .ItemCard, .ItemList .list .ItemCard2, .ItemList .list .shopItemCard, .ItemList .list .storyItemCard {
          margin-bottom: 20px;
          width: calc(98% / 2);
          padding: 0 calc(2 * var(--story-gap-horizontal) / 2 - 0.3%); }
          .ItemList .list .ItemCard img, .ItemList .list .ItemCard2 img, .ItemList .list .shopItemCard img, .ItemList .list .storyItemCard img {
            height: auto !important; } }
      .ItemList .list .ItemCard a, .ItemList .list .ItemCard2 a, .ItemList .list .shopItemCard a, .ItemList .list .storyItemCard a {
        display: inline-block;
        width: 100%; }
        .ItemList .list .ItemCard a:hover, .ItemList .list .ItemCard2 a:hover, .ItemList .list .shopItemCard a:hover, .ItemList .list .storyItemCard a:hover {
          text-decoration: none; }
      .ItemList .list .ItemCard h1, .ItemList .list .ItemCard2 h1, .ItemList .list .shopItemCard h1, .ItemList .list .storyItemCard h1 {
        font-size: 22px;
        font-weight: normal;
        color: #000;
        letter-spacing: 0.1px;
        line-height: 26px;
        margin-bottom: 0px;
        /*======================+MOBILE-FIRST+==========================================*/ }
        @media screen and (max-width: 767px) {
          .ItemList .list .ItemCard h1, .ItemList .list .ItemCard2 h1, .ItemList .list .shopItemCard h1, .ItemList .list .storyItemCard h1 {
            font-size: 12.5px;
            margin-bottom: 0px;
            line-height: 16px; } }
      .ItemList .list .ItemCard .price, .ItemList .list .ItemCard2 .price, .ItemList .list .shopItemCard .price, .ItemList .list .storyItemCard .price {
        color: #96989E;
        margin-top: 0px;
        font-size: 12.5px; }
      .ItemList .list .ItemCard img, .ItemList .list .ItemCard2 img, .ItemList .list .shopItemCard img, .ItemList .list .storyItemCard img {
        width: 100%;
        object-fit: cover;
        max-height: 330px;
        height: 330px; }
    .ItemList .list .storyItemCard {
      /*======================+MOBILE-FIRST+==========================================*/ }
      @media screen and (max-width: 767px) {
        .ItemList .list .storyItemCard {
          width: 100%; } }
      .ItemList .list .storyItemCard a, .ItemList .list .storyItemCard h1, .ItemList .list .storyItemCard .price {
        font-family: Baskerville;
        font-size: 24px;
        line-height: 32px; }

.User > h1 {
  /*======================+MOBILE-FIRST+==========================================*/
  /*======================+MOBILE-FIRST+==========================================*/ }
  @media screen and (max-width: 767px) {
    .User > h1 {
      font-size: 34px;
      text-align: unset; } }
  @media (min-width: 768px) {
    .User > h1 {
      margin-bottom: 35px; } }

.User .story-type-filters {
  display: flex;
  /*======================+MOBILE-FIRST+==========================================*/ }
  @media (min-width: 768px) {
    .User .story-type-filters {
      display: block; } }

.User .selected-filters.my-stories {
  margin-left: 0px;
  /*======================+MOBILE-FIRST+==========================================*/ }
  @media (min-width: 768px) {
    .User .selected-filters.my-stories {
      margin-left: -40px; } }

.User .select-container {
  /*======================+MOBILE-FIRST+==========================================*/
  height: 40px !important;
  margin-left: auto;
  margin-bottom: 15px;
  min-width: 195px;
  width: 195px;
  margin-bottom: 0;
  min-width: 210px;
  width: 210px;
  display: flex; }
  @media screen and (max-width: 767px) {
    .User .select-container {
      display: none; } }
  .User .select-container div[class$="-control"] {
    height: 40px !important;
    min-height: 32px !important;
    box-shadow: none !important;
    border: solid 1px #cecece !important;
    border-radius: 4px; }
    .User .select-container div[class$="-control"] span[class$="-indicatorSeparator"] {
      display: none; }
    .User .select-container div[class$="-control"] div[class$="-singleValue"] {
      font-size: 16px;
      margin-top: px; }
    .User .select-container div[class$="-control"] div[class$="-indicatorContainer"] svg {
      transform: scale(0.6);
      fill: black;
      margin-top: -2px; }
  .User .select-container div[class$="-menu"] {
    border-radius: 4px !important;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0px 2px 6px 4px rgba(0, 0, 0, 0.1) !important; }
  .User .select-container div[class$="-MenuList"] {
    padding-bottom: 8px;
    padding-top: 8px; }
  .User .select-container div[class$="-option"] {
    padding: 10px 12px; }
  .User .select-container > span {
    white-space: nowrap;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 16px;
    margin-right: 8px; }
  .User .select-container > div {
    width: 100%; }

.User .filters {
  /*======================+MOBILE-FIRST+==========================================*/ }
  @media screen and (max-width: 767px) {
    .User .filters {
      display: flex;
      margin-bottom: 15px; } }
  .User .filters li {
    /*======================+MOBILE-FIRST+==========================================*/ }
    @media screen and (max-width: 767px) {
      .User .filters li {
        transform-origin: left;
        margin-bottom: 8px; } }

.User .story-type-filters-container {
  display: flex;
  /*======================+MOBILE-FIRST+==========================================*/ }
  @media (min-width: 768px) {
    .User .story-type-filters-container .story-type-filters {
      margin: auto; } }
  .User .story-type-filters-container .sort-by-container {
    display: none;
    /*======================+MOBILE-FIRST+==========================================*/ }
    @media (min-width: 768px) {
      .User .story-type-filters-container .sort-by-container {
        display: flex; } }
  .User .story-type-filters-container.my-stories {
    display: unset;
    font-size: 22px;
    /*======================+MOBILE-FIRST+==========================================*/ }
    .User .story-type-filters-container.my-stories > span {
      display: block;
      /*======================+MOBILE-FIRST+==========================================*/ }
      @media screen and (max-width: 767px) {
        .User .story-type-filters-container.my-stories > span {
          display: none; } }
    @media (min-width: 768px) {
      .User .story-type-filters-container.my-stories {
        display: flex; }
        .User .story-type-filters-container.my-stories > span {
          margin-top: 5px; } }

.User .user-header-container {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  position: relative;
  /*======================+MOBILE-FIRST+==========================================*/ }
  .User .user-header-container .hidden-md {
    display: none !important; }
  @media screen and (max-width: 767px) {
    .User .user-header-container .hidden-sm {
      display: none !important; }
    .User .user-header-container .hidden-md {
      display: flex !important; }
    .User .user-header-container .more-info {
      position: relative;
      margin-top: 0px; } }
  .User .user-header-container button {
    padding-block: 1px;
    border: 1px solid #96989E;
    border-radius: 4px;
    color: #6E6F6F;
    font-size: 14px;
    min-height: 32px;
    /*======================+MOBILE-FIRST+==========================================*/ }
    @media screen and (max-width: 767px) {
      .User .user-header-container button {
        border: none;
        max-width: 60px;
        margin-top: 50px;
        position: absolute;
        padding: 0px;
        height: 30px;
        right: 0px;
        min-height: 10px; } }
  .User .user-header-container button:hover, .User .user-header-container button:active, .User .user-header-container button:focus {
    background-color: #fff;
    border: 1px solid #96989E; }

.User .user-page-title-container {
  display: flex;
  flex-direction: row; }
  .User .user-page-title-container img {
    background-color: #131413;
    width: 64px;
    height: 64px;
    margin-right: 24px; }

.User .create-a-story {
  margin-bottom: 25px;
  display: flex;
  /*======================+MOBILE-FIRST+==========================================*/ }
  @media (min-width: 768px) {
    .User .create-a-story {
      display: block;
      margin-bottom: 0px; }
      .User .create-a-story.my-stories {
        margin-top: 30px; } }
  .User .create-a-story button {
    /*======================+MOBILE-FIRST+==========================================*/ }
    @media screen and (max-width: 767px) {
      .User .create-a-story button {
        width: auto; } }

.User .ItemList .list {
  /*======================+MOBILE-FIRST+==========================================*/
  /*======================+MOBILE-FIRST+==========================================*/
  /*======================+MOBILE-FIRST+==========================================*/
  /*======================+MOBILE-FIRST+==========================================*/ }
  @media (min-width: 1200px) {
    .User .ItemList .list .ItemCard:nth-child(11n), .User .ItemList .list .ItemCard:nth-child(11n-1), .User .ItemList .list .ItemCard:nth-child(11n-2), .User .ItemList .list .ItemCard:nth-child(11n-3) {
      width: calc(100% / 4);
      height: 410px; }
      .User .ItemList .list .ItemCard:nth-child(11n) img, .User .ItemList .list .ItemCard:nth-child(11n-1) img, .User .ItemList .list .ItemCard:nth-child(11n-2) img, .User .ItemList .list .ItemCard:nth-child(11n-3) img {
        height: 315px;
        object-fit: contain;
        transition: transform 0.2s ease-in-out;
        object-position: bottom; }
      .User .ItemList .list .ItemCard:nth-child(11n):hover img, .User .ItemList .list .ItemCard:nth-child(11n-1):hover img, .User .ItemList .list .ItemCard:nth-child(11n-2):hover img, .User .ItemList .list .ItemCard:nth-child(11n-3):hover img {
        transform: translateY(-10px); }
      .User .ItemList .list .ItemCard:nth-child(11n) h1, .User .ItemList .list .ItemCard:nth-child(11n) .price, .User .ItemList .list .ItemCard:nth-child(11n-1) h1, .User .ItemList .list .ItemCard:nth-child(11n-1) .price, .User .ItemList .list .ItemCard:nth-child(11n-2) h1, .User .ItemList .list .ItemCard:nth-child(11n-2) .price, .User .ItemList .list .ItemCard:nth-child(11n-3) h1, .User .ItemList .list .ItemCard:nth-child(11n-3) .price {
        font-size: 12.5px;
        letter-spacing: 0.1px;
        line-height: 17px;
        margin-bottom: 0px; }
    .User .ItemList .list .ItemCard:nth-child(11n-4), .User .ItemList .list .ItemCard:nth-child(11n-5), .User .ItemList .list .ItemCard:nth-child(11n-6), .User .ItemList .list .ItemCard:nth-child(11n-7), .User .ItemList .list .ItemCard:nth-child(11n-8) {
      width: calc(100% / 5);
      height: 350px; }
      .User .ItemList .list .ItemCard:nth-child(11n-4) h1, .User .ItemList .list .ItemCard:nth-child(11n-4) .price, .User .ItemList .list .ItemCard:nth-child(11n-5) h1, .User .ItemList .list .ItemCard:nth-child(11n-5) .price, .User .ItemList .list .ItemCard:nth-child(11n-6) h1, .User .ItemList .list .ItemCard:nth-child(11n-6) .price, .User .ItemList .list .ItemCard:nth-child(11n-7) h1, .User .ItemList .list .ItemCard:nth-child(11n-7) .price, .User .ItemList .list .ItemCard:nth-child(11n-8) h1, .User .ItemList .list .ItemCard:nth-child(11n-8) .price {
        font-size: 12.5px;
        letter-spacing: 0.1px;
        line-height: 17px;
        margin-bottom: 0px; }
      .User .ItemList .list .ItemCard:nth-child(11n-4) img, .User .ItemList .list .ItemCard:nth-child(11n-5) img, .User .ItemList .list .ItemCard:nth-child(11n-6) img, .User .ItemList .list .ItemCard:nth-child(11n-7) img, .User .ItemList .list .ItemCard:nth-child(11n-8) img {
        height: 240px;
        object-fit: contain;
        transition: transform 0.2s ease-in-out;
        object-position: bottom; }
      .User .ItemList .list .ItemCard:nth-child(11n-4):hover img, .User .ItemList .list .ItemCard:nth-child(11n-5):hover img, .User .ItemList .list .ItemCard:nth-child(11n-6):hover img, .User .ItemList .list .ItemCard:nth-child(11n-7):hover img, .User .ItemList .list .ItemCard:nth-child(11n-8):hover img {
        transform: translateY(-10px); } }
  @media screen and (max-width: 767px) {
    .User .ItemList .list .ItemCard {
      height: auto;
      margin-top: 0px; }
      .User .ItemList .list .ItemCard img {
        height: 225px !important; }
      .User .ItemList .list .ItemCard:nth-child(16n-15), .User .ItemList .list .ItemCard:nth-child(16n-14) {
        height: 360px;
        width: 100%; }
        .User .ItemList .list .ItemCard:nth-child(16n-15) img, .User .ItemList .list .ItemCard:nth-child(16n-14) img {
          transition: transform 0.2s ease-in-out;
          object-position: center;
          height: 260px !important; }
        .User .ItemList .list .ItemCard:nth-child(16n-15) h1, .User .ItemList .list .ItemCard:nth-child(16n-15) .price, .User .ItemList .list .ItemCard:nth-child(16n-14) h1, .User .ItemList .list .ItemCard:nth-child(16n-14) .price {
          font-size: 24px;
          letter-spacing: 0.2px;
          line-height: 28px;
          margin-bottom: 0px; }
        .User .ItemList .list .ItemCard:nth-child(16n-15) .price, .User .ItemList .list .ItemCard:nth-child(16n-14) .price {
          font-size: 16px; } }
  @media (min-width: 1200px) {
    .User .ItemList .list .shopItemCard {
      width: calc(100% / 5); }
      .User .ItemList .list .shopItemCard h1, .User .ItemList .list .shopItemCard .price {
        font-size: 12.5px;
        letter-spacing: 0.1px;
        line-height: 17px;
        margin-bottom: 0px; }
      .User .ItemList .list .shopItemCard img {
        height: 240px;
        object-fit: contain;
        transition: transform 0.2s ease-in-out;
        object-position: bottom; }
      .User .ItemList .list .shopItemCard:hover img {
        transform: translateY(-10px); }
    .User .ItemList .list .ItemCard2 {
      width: calc(100% / 5); }
      .User .ItemList .list .ItemCard2 h1, .User .ItemList .list .ItemCard2 .price {
        font-size: 12.5px;
        letter-spacing: 0.1px;
        line-height: 17px;
        margin-bottom: 0px; }
      .User .ItemList .list .ItemCard2 img {
        height: 240px;
        object-fit: contain;
        transition: transform 0.2s ease-in-out;
        object-position: bottom; }
      .User .ItemList .list .ItemCard2:hover img {
        transform: translateY(-10px); } }
  @media screen and (max-width: 767px) {
    .User .ItemList .list .ItemCard2 img {
      height: 225px !important;
      object-fit: contain;
      object-position: bottom; } }

.item-filter div.selected-filter .toggle-button button {
  border: 1px solid black;
  color: black;
  /*======================+MOBILE-FIRST+==========================================*/ }
  .item-filter div.selected-filter .toggle-button button:focus, .item-filter div.selected-filter .toggle-button button:hover, .item-filter div.selected-filter .toggle-button button:active {
    color: white; }
  @media screen and (max-width: 767px) {
    .item-filter div.selected-filter .toggle-button button {
      font-size: 12px; } }

.item-filter div.selected-filter .toggle-button.filter-option-container-selected button {
  border: 1px solid black;
  color: white;
  background: black; }
  .item-filter div.selected-filter .toggle-button.filter-option-container-selected button:focus, .item-filter div.selected-filter .toggle-button.filter-option-container-selected button:hover, .item-filter div.selected-filter .toggle-button.filter-option-container-selected button:active {
    color: white; }
    .item-filter div.selected-filter .toggle-button.filter-option-container-selected button:focus svg, .item-filter div.selected-filter .toggle-button.filter-option-container-selected button:hover svg, .item-filter div.selected-filter .toggle-button.filter-option-container-selected button:active svg {
      fill: black !important; }
  .item-filter div.selected-filter .toggle-button.filter-option-container-selected button svg {
    fill: white !important; }

.Shop .header-filter button {
  height: 40px; }

.User .header-filter button {
  /*======================+MOBILE-FIRST+==========================================*/ }
  @media screen and (max-width: 767px) {
    .User .header-filter button {
      min-height: 31px; } }

.pointer {
  cursor: pointer; }

.containers {
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	max-width: 600px;
	padding-top: 2.8em;
	position: relative;
}
.h1p {
	padding-left: 3px;
}
.button {
	background: black;
	color: white;
	border: none;
	width: 40px;
	height: 40px;
	min-width: 40px;
	border-radius: 100%;
	outline: none;
	transition: all 0.2s ease;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin-top: 10px;
}
.button .play-icon {
	height: 18px;
	width: 18px;
	margin-left: 4px;
	margin-top: 1px;
}
.button .pause-icon {
	height: 29px;
	width: 29px;
}

/*button:hover {*/
/*    background: #d63d05;*/
/*}*/

.playlist {
	text-align: left;
	border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.playlist-item {
	color: #444;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	cursor: pointer;
	padding: 1em;
}

.playlist-item.selected {
	font-weight: bold;
}

.playlist-item:hover {
	background: rgba(0, 0, 0, 0.1);
}

.controls {
	margin: 0 auto;
	padding: 1em;
	z-index: 1000;
}

.scroll-audio {
	position: absolute;
	top: 54%;
	width: 100%;
	z-index: 10;
	transform: scaleY(9);
	opacity: 0;
}
.audio-wave {
}
.audio-wave wave {
	border-right: 0 !important;
}
.meta {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-bottom: 34px;
}
.meta .title {
	text-align: center;
	font-size: 30px;
	line-height: 1.2;
	font-family: Times New Roman;
	color: #201f1e;
}
.meta .sub-title {
	text-align: center;
	font-size: 16px;
	line-height: 1.2;
	font-family: Times New Roman;
	font-weight: 300;
	color: #201f1e;
	margin-top: 6px;
}
.meta.videos {
	margin-top: 30px;
}

@media (max-width: 992px) {
	.meta .title {
		font-size: 22px;
	}
	.meta .sub-title {
		font-size: 14px;
	}
}

@media (max-width: 480px) {
	.meta {
		padding: 0px 4px;
	}
	.audio-wave {
		padding: 0px 30px 0px 35px;
	}
	.audio-wave wave {
		height: 160px;
	}
	.scroll-audio {
		top: 52%;
	}
}

