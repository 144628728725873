.User {
  .loadmore {
    border-radius: 4px;
    height: 40px;
    font-size: 16px;
    @include breakpoint(sm-down) {
      max-width: 210px;
    }

  }
  .shopTitle {
    margin-bottom: 16px;
    margin-top: 0px;
    text-align: unset;
    font-size: 40px;
    @include breakpoint(sm-down) {
      font-size: 24px;
      margin-bottom: 0px;
    }
  }
  .shopLocation {
    font-size: 14px;
    @include breakpoint(sm-down) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .filter-list {
    justify-content: unset;
    @include breakpoint(md) {
      justify-content: center;
    }
  }
  .item-filter-container {
    .header-filter {
      display: flex;
      flex-wrap: wrap;
      margin-top: 24px;
      button {
        margin-right: 16px;
        border-radius: 4px;
        font-size: 16px;
        margin-bottom: 16px;
        @include breakpoint(sm-down) {
          font-size: 12px;
          width: auto;
          margin-right: 12px;
          padding-block: 0px;
          max-height: 30px;
          margin-bottom: 12px;

        }
      }

      .hovered-button {
        color: #fff;
        background-color: #000;
      }
    }
    &.filter-children-opened {
      margin-bottom: 40px;
    }
    .filter-description {
      margin-top: 16px;
      font-size: 14.5px;
      margin-bottom: 16px;
    }
    .item-filter-wrapper {
      align-items: center;
      display: flex;
      .filter-option-container {
        &:not(:last-child) {
          margin-right: 16px;
          @include breakpoint(sm-down) {
            margin-right: 8px;
          }
        }

      }

      > *:not(:last-child) {
        &.item-filter {
          margin-bottom: 8px;
        }
      }
    }
    .item-filter button {
      font-size: 16px;
      padding: 9px 16px;
      width: auto !important;
      @include breakpoint(sm-down) {
        padding-block: 7px;
      }
    }
  }
  .go-back {
    background-color: #000;
    color: #fff;
    border-radius: 4px;
    svg {
      margin-right: 10px;
    }
    &:hover {
      background-color: #000;
      color: #fff;
      svg {
        filter: invert(0);
      }
    }
    &:focus {
      svg {
        filter: invert(0);
      }
    }
    @include breakpoint(sm-down) {
      width: 150px;
      min-height: 32px;
      font-size: 14px;
      padding: 0px;

      svg {
        width: 12px;
      }
    }

  }

  .info-container {
    font-size: 14px;
    display: flex;
    a {
      display: flex;
      align-items: center;
      svg {
        margin-right: 8px;
      }
    }

    div:first-child {
      margin-right: 30px;
    }
  }
  .info-details {
    h2 {
      margin-top: 24px;
      margin-bottom: 12px;
    }
    p {
      font-size: 14px;
      line-height: 20px;
    }
    display: flex;
    justify-content: space-between;
    div {
      max-width: 50%;
      img {
        display: block;
      }
    }


    .shipping-container {
      font-size: 14px;
      letter-spacing: 0.2px;
      line-height: 20px;
      display: grid;
      max-width: 100%;
      grid-template-columns: auto auto;

      .shipping-item {
        margin-bottom: 20px;
        max-width: 90%;
        @include breakpoint(sm-down) {
          margin-bottom: 10px;
          max-width: 60%;
        }
        h4 {
          font-weight:500;
          margin: 0px;
        }

        .cost {
          max-width: 100%;
          display: flex;
          justify-content: space-between;

          .price {
            width: 120px;
            display: flex;
            align-items: flex-start;
          }
        }
      }

    }
    @include breakpoint(sm-down) {
      div {
        max-width: 100%;
      }
      .shipping-container {
        grid-template-columns: auto;
      }
    }
    .orderTerms {
      h1 {
        margin-top: 25px;
        margin-bottom: 0px;
        font-size: 1.5em;
      }
      p {
        margin: 0px;
      }
    }
  }
}

.ItemList {
  margin-top: 24px;
  @include breakpoint(md) {
    margin-top: 24px;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 calc(-1 * var(--story-gap-horizontal) / 2);
    --story-gap-horizontal: 28px;
    --story-gap-vertical: 40px;
    --story-number-items: 1;
    @include breakpoint(sm-down) {
      --story-gap-horizontal: 10px;
    }
    @include breakpoint(md) {
      --story-gap-horizontal: 100% / 12;
      --story-gap-vertical: 32x;
      --story-number-items: 2;
      margin: 0 calc(-1 * var(--story-gap-horizontal) / 2);
    }
    @include breakpoint(xl) {
      --story-gap-horizontal: 100% / 12;
      --story-gap-vertical: 32px;
      --story-number-items: 2;
      margin: 0 calc(-1 * var(--story-gap-horizontal) / 2);
    }
    .ItemCard, .ItemCard2, .shopItemCard, .storyItemCard {
      display: inline-block;
      margin: auto;
      margin-top: 0px;
      width: calc(100% / var(--story-number-items));
      padding: 0 calc(1 * var(--story-gap-horizontal) / 2 - 0.3%);
      margin-bottom: var(--story-gap-vertical);
      height: auto;
      @include breakpoint(sm-down) {
        margin-bottom: 20px;
        width: calc(98% / 2);
        padding: 0 calc(2 * var(--story-gap-horizontal) / 2 - 0.3%);
        img {
          height: auto !important;
        }
      }
      a {
        display: inline-block;
        width: 100%;
        &:hover {
          text-decoration: none;
        }
      }

      h1 {
        font-size: 22px;
        font-weight: normal;
        color: #000;
        letter-spacing: 0.1px;
        line-height: 26px;
        margin-bottom: 0px;
        @include breakpoint(sm-down) {
          font-size: 12.5px;
          margin-bottom: 0px;
          line-height: 16px;
        }
      }

      .price {
        color: #96989E;
          margin-top: 0px;
        font-size: 12.5px;
      }

      img {
        width: 100%;
        object-fit: cover;
        max-height: 330px;
        height: 330px;
      }
    }
    .storyItemCard {
      @include breakpoint(sm-down) {
        width: 100%;
      }
      a, h1, .price {
        font-family: Baskerville;
          font-size: 24px;
          line-height: 32px;
      }
    }
  }
}

.User {
  > h1 {
    @include breakpoint(sm-down) {
      font-size: 34px;
      text-align: unset;
    }
    @include breakpoint(md) {
      margin-bottom: 35px;
    }
  }
  .story-type-filters {
    display: flex;
    @include breakpoint(md) {
      display: block;
    }
  }
  .selected-filters.my-stories {
    margin-left: 0px;
    @include breakpoint(md) {
      margin-left: -40px;
    }
  }
.select-container {
  @include breakpoint(sm-down) {
    display: none;
  }
  height: 40px !important;
  div[class$="-control"] {
    height: 40px !important;
    min-height: 32px !important;
    box-shadow: none !important;
    border: solid 1px #cecece !important;
    border-radius: 4px;
    span[class$="-indicatorSeparator"] {
      display: none;
    }
    div[class$="-singleValue"] {
      font-size: 16px;
      margin-top: px;
    }
    div[class$="-indicatorContainer"] svg {
      transform: scale(0.6);
      fill: black;
      margin-top: -2px;
    }
  }
  div[class$="-menu"] {
    border-radius: 4px !important;
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0px 2px 6px 4px hsla(0, 0%, 0%, 0.1) !important;
  }
  div[class$="-MenuList"] {
    padding-bottom: 8px;
    padding-top: 8px;
  }
  div[class$="-option"] {
    padding: 10px 12px;
  }
  margin-left: auto;
  margin-bottom: 15px;
  min-width: 195px;
  width: 195px;
  margin-bottom: 0;
  min-width: 210px;
  width: 210px;
  display: flex;
  > span {
    white-space: nowrap;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 16px;
    margin-right: 8px;
  }
  > div {
    width: 100%;
  }
}

  .filters {
    @include breakpoint(sm-down) {
      display: flex;
      margin-bottom: 15px;
    }
    li {
      @include breakpoint(sm-down) {
        // transform: scale(0.9);
        transform-origin: left;
        // padding-right: 0px;
        margin-bottom: 8px;
      }
    }
  }
  .story-type-filters-container {
    display: flex;
    @include breakpoint(md) {
      .story-type-filters {
        margin: auto;
      }
    }
    .sort-by-container {
      display: none;
      @include breakpoint(md) {
        display: flex;
      }
    }
    &.my-stories {
      display: unset;
      font-size: 22px;
      > span {
        display: block;
        @include breakpoint(sm-down) {
          display: none;
        }
      }
      @include breakpoint(md) {
        display: flex;
        > span {
          margin-top: 5px;
        }
      }
    }
  }
  .user-header-container {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    position:relative;
    
    .hidden-md {
      display: none !important;
    }
    @include breakpoint(sm-down) {
      .hidden-sm {
        display: none !important;
      }
      .hidden-md {
        display: flex !important;
      }

      .more-info {
        position: relative;
        margin-top: 0px;
      }
    }

    button {
      padding-block: 1px;
      border: 1px solid #96989E;
      border-radius: 4px;
      color: #6E6F6F;
      font-size: 14px;
      min-height: 32px;
      @include breakpoint(sm-down) {
        border: none;
        max-width: 60px;
        margin-top: 50px;
        position:absolute;
        padding: 0px;
        height: 30px;
        right: 0px;
        min-height: 10px;
      }
    }
    button:hover, button:active, button:focus {
      background-color: #fff;
      border: 1px solid #96989E;
    }
  }
  .user-page-title-container {
    display: flex;
    flex-direction: row;
    
    img {
      background-color: #131413;
      width: 64px;
      height: 64px;
      margin-right: 24px;
    }
    
  }
  .create-a-story {
    // display: none;
    margin-bottom: 25px;
    display: flex;
    @include breakpoint(md) {
      display: block;
      margin-bottom: 0px;
      &.my-stories {
        margin-top: 30px;
      }
    }
    button {
      @include breakpoint(sm-down) {
        width: auto;
      }
    }
  }
  .ItemList .list {
    @include breakpoint(xl) {
      .ItemCard {
        &:nth-child(11n),
        &:nth-child(11n-1),
        &:nth-child(11n-2),
        &:nth-child(11n-3) {
          width: calc(100% / 4);
          height: 410px;
          img {
            height: 315px;
            object-fit: contain;
            transition: transform 0.2s ease-in-out;
            object-position: bottom;
          }
          &:hover {
            img {
             transform: translateY(-10px);
           }
         }

          h1, .price {
            font-size: 12.5px;
            letter-spacing: 0.1px;
            line-height: 17px;
            margin-bottom: 0px;
          }

        }
        &:nth-child(11n-4),
        &:nth-child(11n-5),
        &:nth-child(11n-6),
        &:nth-child(11n-7),
        &:nth-child(11n-8) {
          width: calc(100% / 5);
          height: 350px;
          h1, .price {
             font-size: 12.5px;
            letter-spacing: 0.1px;
            line-height: 17px;
            margin-bottom: 0px;
          }
          img {
            height: 240px;
            object-fit: contain;
            transition: transform 0.2s ease-in-out;
            object-position: bottom;
          }
          &:hover {
            img {
             transform: translateY(-10px);
           }
         }
        }
      }
    }
    @include breakpoint(sm-down) {
      .ItemCard {
        height: auto;
        margin-top: 0px;
        img {
          height: 225px !important;
        }
        &:nth-child(16n-15),
        &:nth-child(16n-14)
        {
          height: 360px;
          width: 100%;
          img {
            transition: transform 0.2s ease-in-out;
            object-position: center;
            height: 260px !important;
          }

          h1, .price {
            font-size: 24px;
            letter-spacing: 0.2px;
            line-height: 28px;
            margin-bottom: 0px;
          }

          .price {
            font-size: 16px;
          }

        }
      }
    }
    @include breakpoint(xl) {
      .shopItemCard  {
        width: calc(100% / 5);
        h1, .price {
          font-size: 12.5px;
          letter-spacing: 0.1px;
          line-height: 17px;
          margin-bottom: 0px;
        }
        img {
          height: 240px;
          object-fit: contain;
          transition: transform 0.2s ease-in-out;
          object-position: bottom;
        }
        &:hover {
          img {
           transform: translateY(-10px);
         }
       }
      }
      .ItemCard2 {
        & {
          width: calc(100% / 5);
          h1, .price {
         font-size: 12.5px;
          letter-spacing: 0.1px;
          line-height: 17px;
          margin-bottom: 0px;
          }
          img {
            height: 240px;
            object-fit: contain;
            transition: transform 0.2s ease-in-out;
            object-position: bottom;
          }
          &:hover {
            img {
             transform: translateY(-10px);
           }
         }
        }
      }
    }

    @include breakpoint(sm-down) {
      .ItemCard2 {
        img {
          height: 225px !important;
          object-fit: contain;
          object-position: bottom;
        }
      }
    }

    
  }
}


.item-filter div.selected-filter .toggle-button {
  button {
    border: 1px solid black;
    color: black;
    &:focus,
    &:hover,
    &:active {
      color: white;
    }
    @include breakpoint(sm-down) {
      font-size: 12px;
    }
  }
  &.filter-option-container-selected button {
    border: 1px solid black;
    color: white;
    background: black;
    &:focus,
    &:hover,
    &:active {
      color: white;
      svg {
        fill: black !important;
      }
    }
    svg {
      fill: white !important;
    }
  }
}


.Shop .header-filter button{
  height: 40px;
}
.User .header-filter button{
  @include breakpoint(sm-down) {
    min-height: 31px;
  }
}

.pointer {
  cursor: pointer;
}