/*=========================================================
  01. #PADDING & MARGIN
=========================================================*/
// HOW TO USE, helper classe margin, padding
// pd = padding, mg = margin, -b,-t,-l,r = bottom,top,left,right
// pd-b-0..16..22..
// pd-t-0..16..22..
// pd-l-0..16..22..
// pd-r-0..16..22..
// +
// mg-b-0..16..22..
// mg-t-0..16..22..
// mg-l-0..16..22..
// mg-r-0..16..22..

// $right-space-vars:                                                     (0 right r,);
// $bottom-space-vars:                                                    (0 bottom b,);
// $top-space-vars:                                                       (0 top t,);
// @mixin generate-margin-classes( $default-space-amounts-with-direction: (0 left l,) ) {
//   @each $space in $default-space-amounts-with-direction {
//     .mg-#{nth($space, 3)}-#{nth($space, 1)} {
//       margin-#{nth($space, 2)}: #{nth($space, 1)}px;
//     }
//   }
// }

// @mixin generate-padding-classes( $default-space-amounts-with-direction: (0 left l,) ) {
//   @each $space in $default-space-amounts-with-direction {
//     .pd-#{nth($space, 3)}-#{nth($space, 1)} {
//       padding-#{nth($space, 2)}: #{nth($space, 1)}px;
//     }
//   }
// }

// @include generate-margin-classes();
// @include generate-margin-classes($right-space-vars);
// @include generate-margin-classes($bottom-space-vars);
// @include generate-margin-classes($top-space-vars);

// @include generate-padding-classes();
// @include generate-padding-classes($right-space-vars);
// @include generate-padding-classes($bottom-space-vars);
// @include generate-padding-classes($top-space-vars);

// @mixin generate-margin-classes-sm( $default-space-amounts-with-direction: (0 left l,) ) {
//   @each $space in $default-space-amounts-with-direction {
//     .sm-mg-#{nth($space, 3)}-#{nth($space, 1)} {
//       @include breakpoint(sm-down){
//         margin-#{nth($space, 2)}: #{nth($space, 1)}px;
//       }
//     }
//   }
// }

// @mixin generate-padding-classes-sm( $default-space-amounts-with-direction: (0 left l, ) ) {
//   @each $space in $default-space-amounts-with-direction {
//     .sm-pd-#{nth($space, 3)}-#{nth($space, 1)} {
//       @include breakpoint(sm-down){
//         padding-#{nth($space, 2)}: #{nth($space, 1)}px;
//       }
//     }
//   }
// }

// @include generate-margin-classes-sm();
// @include generate-margin-classes-sm($right-space-vars);
// @include generate-margin-classes-sm($bottom-space-vars);
// @include generate-margin-classes-sm($top-space-vars);

// @include generate-padding-classes-sm();
// @include generate-padding-classes-sm($right-space-vars);
// @include generate-padding-classes-sm($bottom-space-vars);
// @include generate-padding-classes-sm($top-space-vars);

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.clearfix {
  clear: both;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

a {
  cursor: pointer;
}
