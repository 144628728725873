.item-filter {
  @include breakpoint(sm-down) {
    // margin-top: -px;
  }
  div {
    display: flex;
    // max-width: 100%;
    flex-wrap: wrap;
    &.selected-filter button {
      &:focus,
      &:hover,
      &:active {
        color: white;
        border: 1px solid black;
        svg {
          fill: black !important;
        }
      }
    }
    .filter-option-container {
      margin-top: 20px;
      @include breakpoint(sm-down) {
        margin-top: 12px;
      }
      &:not(:last-child) {
        margin-right: 8px;
      }
      .selected-button {
        background-color: #5B48B6;
        border: none;
        color: #fff;
        &:focus,
        &:hover,
        &:active {
          border:none;
        }
        svg {
          fill: #fff !important;
        }
      }
      button {
        border-radius: 4px;
        padding: 0;
        height: unset !important;
        min-height: unset !important;
        font-size: 16px;
        height: 24px;
        flex: 0 0 auto;
        padding: 6px 15px;

        white-space: wrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }
      .filter-button-content {
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
      .filter-left-icon {
        margin-right: 10px;
      }
      .filter-right-icon {
        margin-left: 10px;
      }
    }
  }
  &.big {
    div .filter-option-container {
      &:not(:last-child) {
        margin-right: 16px;
        @include breakpoint(sm-down) {
          margin-right: 12px;
        }
      }
      
      button {
        font-size: 16px;
        border-radius: 7px;
        padding: 11px 16px !important;
        @include breakpoint(sm-down) {
          font-size: 12px;
          max-height: 30px;
          justify-content: center;
          padding-block: 0px;
          display: flex;
          border-radius: 4px;
        }
      }
    }
  }
}
