/*=========================================================
///////////////////////////////////////////////////////////
  FONTS
///////////////////////////////////////////////////////////
=========================================================*/
@import url("https://use.typekit.net/sug5znb.css");

@font-face {
  font-family: "Baskerville";
  src: local("Baskerville"), url("/fonts/baskerville-normal.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "Baskerville";
  src: local("Baskerville"), url("/fonts/baskerville-regular.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}
* {
  font-weight: lighter;
}
// @font-face {
//   font-family: 'Nimbus sans novus';
//   src: local('Nimbus-sans-novus'),
//         url('/fonts/nimbus-sans-novus-regular.woff2') format('woff2'),
//         url('/fonts/nimbus-sans-novus-regular.woff') format('woff'),
//         url('/fonts/nimbus-sans-novus-regular.ttf') format('truetype');
//   font-weight:  400;
//   font-style:   normal;
//   font-stretch: normal;
// }
// @font-face {
//   font-family: 'Nimbus sans novus';
//   src: local('Nimbus-sans-novus'),
//         url('/fonts/nimbus-sans-novus-medium.woff2') format('woff2'),
//         url('/fonts/nimbus-sans-novus-medium.woff') format('woff'),
//         url('/fonts/nimbus-sans-novus-medium.ttf') format('truetype');
//   font-weight:  500;
//   font-style:   normal;
//   font-stretch: normal;
// }
//700 300  not used
// @font-face {
//   font-family: 'Nimbus sans novus';
//   src: local('Nimbus sans novus'), local('Nimbus-sans-novus'),
//         url('/fonts/nimbus-sans-novus-bold.woff') format('woff'),
//         url('/fonts/nimbus-sans-novus-bold.ttf') format('truetype');
//   font-weight:  700;
//   font-style:   normal;
//   font-stretch: normal;
// }
// @font-face {
//   font-family: 'Nimbus sans novus';
//   src: local('Nimbus sans novus'), local('Nimbus-sans-novus'),
//         url('/fonts/nimbus-sans-novus-light.woff') format('woff'),
//         url('/fonts/nimbus-sans-novus-light.ttf') format('truetype');
//   font-weight:  300;
//   font-style:   normal;
//   font-stretch: normal;
// }
