.Catalog{	    
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;  

  --gap-horizontal: 16px;
  --gap-vertical: 40px;
  --number-items: 2;  
  margin: 0 calc(-1 * var(--gap-horizontal) / 2) 40px;
  @include breakpoint(md){
    --gap-horizontal: 48px;
    --gap-vertical: 48px;
    --number-items: 4;  
  } 
  @include breakpoint(xl){
    --gap-horizontal: 96px;
    --gap-vertical: 58px;
    --number-items: 5; 
  }  
  &.xl {
    --number-items: 2;  
    @include breakpoint(md){
      --gap-horizontal: 100px;
      --gap-vertical: 48px;
      --number-items: 3;  
    } 
    @include breakpoint(xl){
      --gap-horizontal: 200px;
      --gap-vertical: 48px;
      --number-items: 3;  
    } 
    .item-text {
      text-align: center;
    }
    .item-img {
      object-position: center center;
    }
  }
  .item-container{
    // width: calc((100% - (var(--number-items) - 1) * var(--gap-horizontal)) / var(--number-items));
    // &:not(:nth-child(2n)){
    //   margin-right: var(--gap-horizontal);
    // } 
    // &:not(:nth-child(-n+2)){
    //   margin-top: var(--gap-vertical);
    // }  
    // @include breakpoint(md){
    //   &:not(:nth-child(4n)){
    //     margin-right: var(--gap-horizontal);
    //   }
    //   &:nth-child(-n+4){
    //     margin-top: 0;
    //   } 
    //   &:nth-child(n+5){
    //     margin-top: var(--gap-vertical);
    //   }
    // }
    width: calc( 100% / var(--number-items) );
    padding: 0 calc(var(--gap-horizontal) / 2);
    margin-bottom: var(--gap-vertical);
  } 
}