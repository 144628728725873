.App {
  margin: 0 auto;
  max-width: $max-content-width;
  min-height: 100vh;

  .main {
    // min-height: calc(100vh - 270px);
    min-height: calc(100vh);
    padding: 0 var(--horizontal-padding) 0;
    @include breakpoint(md) {
      padding: 0 var(--horizontal-padding) 0;
    }
    background-color: $white;
  }
}

.not-display-on-mobile {
  display: none;
  @include breakpoint(md) {
    display: block;
  }
}