.Stories {
  .filter-list {
    justify-content: unset;
    @include breakpoint(md) {
      justify-content: center;
    }
  }
}

.StoryList {
  margin-top: 20px;
  @include breakpoint(md) {
    margin-top: 50px;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 calc(-1 * var(--story-gap-horizontal) / 2);
    --story-gap-horizontal: 28px;
    --story-gap-vertical: 40px;
    --story-number-items: 1;
    @include breakpoint(md) {
      --story-gap-horizontal: 100% / 12;
      --story-gap-vertical: 24px;
      --story-number-items: 2;
      margin: 0 calc(-1 * var(--story-gap-horizontal) / 2);
    }
    @include breakpoint(xl) {
      --story-gap-horizontal: 100% / 12;
      --story-gap-vertical: 24px;
      --story-number-items: 2;
      margin: 0 calc(-1 * var(--story-gap-horizontal) / 2);
    }
    .StoryCard {
      display: inline-block;
      width: calc(100% / var(--story-number-items));
      padding: 0 calc(1 * var(--story-gap-horizontal) / 2 - 0.3%);
      margin-bottom: var(--story-gap-vertical);

      a {
        display: inline-block;
        width: 100%;
        &:hover {
          text-decoration: none;
        }
      }

      h1 {
        font-size: 32px;
        font-family: Baskerville;
        font-weight: normal;
        color: #131413;
        letter-spacing: 0.29px;
        line-height: 38px;
        margin-bottom: 7px;
        @include breakpoint(sm-down) {
          font-size: 27px;
          margin-bottom: 0px;
        }
      }

      .author {
        font-size: 16px;
        line-height: 24px;
      }

      .story-card-header {
        margin-bottom: 17px;
        font-size: 14px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        @include breakpoint(sm-down) {
          display: none;
        }
        .status {
          border-radius: 18px;
          background: #f7f8fa;
          margin-top: -3px;
          padding: 3px 11px;
          color: #a2a2a2;
        }
      }

      img {
        width: 100%;
        object-fit: cover;
        max-height: 330px;
        height: 330px;
        @include breakpoint(sm-down) {
          max-height: 66vw;
        }
      }
    }
  }
}

.Stories {
  .sticky {
    .filters:not(.story-type-filters) {
      // last filter have 'useless to animate' margin-bottom // story-type-filters is the last bottom filter
      transition: margin-bottom 250ms;
    }
    .filters {
      margin-bottom: 0px;
    }
    .sort-by-container {
      display: none !important;
    }
    .stories-count {
      display: none !important;
    }
    .story-type-filters-container.my-stories,
    .filters {
      @include breakpoint(md) {
        display: block !important;
      }
    }
    .filter-container > div {
      margin-left: 0;
      @include breakpoint(xl) {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .selected-filters.my-stories {
      margin-left: 0;
    }
  }
  > h1 {
    @include breakpoint(sm-down) {
      font-size: 34px;
      text-align: unset;
    }
    @include breakpoint(md) {
      margin-bottom: 35px;
    }
  }
  .story-type-filters {
    display: flex;
    @include breakpoint(md) {
      display: block;
    }
  }
  .selected-filters.my-stories {
    margin-left: 0px;
    @include breakpoint(md) {
      margin-left: -40px;
    }
  }

  .filters {
    @include breakpoint(sm-down) {
      display: flex;
      margin-bottom: 15px;
    }
    li {
      @include breakpoint(sm-down) {
        // transform: scale(0.9);
        transform-origin: left;
        // padding-right: 0px;
        margin-bottom: 8px;
      }
    }
  }
  .story-type-filters-container {
    display: flex;
    @include breakpoint(md) {
      .story-type-filters {
        margin: auto;
      }
    }
    .sort-by-container {
      display: none;
      @include breakpoint(md) {
        display: flex;
      }
    }
    &.my-stories {
      display: unset;
      font-size: 22px;
      > span {
        display: block;
        @include breakpoint(sm-down) {
          display: none;
        }
      }
      @include breakpoint(md) {
        display: flex;
        > span {
          margin-top: 5px;
        }
      }
    }
  }
  .stories-page-title-container {
    display: flex;
    flex-direction: column-reverse;
    @include breakpoint(md) {
      display: block;
    }
  }
  .create-a-story {
    // display: none;
    margin-bottom: 25px;
    display: flex;
    @include breakpoint(md) {
      display: block;
      margin-bottom: 0px;
      &.my-stories {
        margin-top: 30px;
      }
    }
    button {
      @include breakpoint(sm-down) {
        width: auto;
      }
    }
  }
  .StoryList .list {
    @include breakpoint(xl) {
      .StoryCard {
        &:nth-child(5n),
        &:nth-child(5n-1),
        &:nth-child(5n-2) {
          width: calc(100% / (var(--story-number-items) + 1));
          margin-bottom: 115px;
          img {
            height: 200px;
          }
        }
      }
    }
  }
}

.StoryPreview {
  margin-top: 30px;
  margin-bottom: 30px;
  .page-title {
    font-size: 3.375em;
    margin-bottom: 17px;
  }
  @include breakpoint(xs-down) {
    .story-label {
      display: flex;
      flex-direction: column-reverse;
      > * {
        margin-right: auto;
      }
      a {
        display: flex;
        button {
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 10px;
        }
      }
      + .story {
        padding-top: 0px;
      }
    }
  }
  .story {
    padding: 25px 0;
    max-width: 886px;
    margin: 0 auto 80px;

    .header {
      .subline {
        display: flex;
        justify-content: center;
        letter-spacing: 0.78px;
        font-size: 0.875em;
        & > * {
          margin: 1em 3em 2em;
        }
      }
      .videoContainer {
        width: 100%;
        margin-bottom: 50px;
        text-align: center;
        max-height: 800px !important;
        overflow: hidden;
        iframe {
          width: 100%;
          max-height: 800px !important;
        }
      }
      img {
        width: 100%;
        margin-bottom: 50px;
      }

      .related-to {
        display: flex;
        div:first-of-type {
          margin-right: 60px;
          font-size: 16px;
        }
        div:last-of-type {
          font-weight: bold;
          font-family: nimbus-sans;
          a {
            display: block;
            margin-bottom: 2px;
          }
          p {
            display: inline-block;
          }
        }
      }


    }
    .content * {
      margin-top: 50px;
      color: #131413;
      font-family: Baskerville;
      font-size: 22px;
      letter-spacing: 1.22px;
      line-height: 32px;
      white-space: break-spaces;
    }
    @media (max-width: (768px)) {
      .header {

        .hkpKuJ {
          text-align: left;
        }
        .subline > * {
          margin-left: 0px;
          justify-content: flex-start;
          margin-right: 5px;
        }
        .related-to {
          display: block;
        }
        p:last-child {
          margin-bottom: 5px;
        }
        .filter-list {
          margin-inline: 0px
        }
      }
    }
  }
}

.story-form .editions-select div[class*="placeholder"] {
  @include breakpoint(sm-down) {
    font-size: 16px;
  }
}

.story-form {
  color: #dc3545;
  .errors-message {
    margin-bottom: 0 !important;
  }
  ul {
    margin-top: 0;
  }
}